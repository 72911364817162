<template>
  <el-select
    v-model="rolesModel"
    @change="rolesChanged"
    autocomplete="new-password"
    :placeholder="$t('COMMON.ROLES')"
    :filterable="filterable"
    :multiple="true"
    :disabled="disabled"
    remote
    :remote-method="getRoles"
    :loading="loading"
  >
    <el-option
      v-for="role in rolesList"
      :key="role.id"
      :value="role.id"
      :label="`${role.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "roles-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    roles: {
      type: Array,
      default: null,
      description: "Roles array",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterOrganizationNull: {
      type: Boolean,
      default: null,
      description: "Filter only null organizations",
    },
    filterReseller: {
      type: String,
      default: null,
      description: "Reseller id",
    },
    filterResellerNull: {
      type: Boolean,
      default: null,
      description: "Filter only null resellers",
    },
    filterPermission: {
      type: String,
      default: null,
      description: "Permission name",
    },
  },

  data() {
    const roles = this.roles ?? [];
    return {
      rolesModel: roles.map((item) => item.id),
      rolesList: {},
    };
  },

  setup() {},

  created() {
    this.getRoles(null, this.roles);
  },

  methods: {
    async getRoles(query = null, roles = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { name: query } : {}),
            ...(roles ? { with_ids: roles.map((item) => item.id) } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.filterOrganization,
            },
          };
        }
        if (this.filterOrganizationNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: null,
            },
          };
        }
        if (this.filterReseller) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: this.filterReseller,
            },
          };
        }
        if (this.filterResellerNull) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              reseller: null,
            },
          };
        }
        if (this.filterPermission) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              permission: this.filterPermission,
            },
          };
        }

        await this.$store.dispatch("roles/list", params);
        const rolesArr = await this.$store.getters["roles/list"];
        this.rolesList = {};
        rolesArr.forEach((role) => {
          this.rolesList[role.id] = role;
        });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    rolesChanged(roles) {
      this.$emit(
        "rolesChanged",
        roles.map((item) => {
          return { id: item, type: "roles" };
        })
      );
    },
  },

  watch: {
    roles(roles) {
      this.rolesModel = roles.map((item) => item.id);
      // this.getRoles(null, this.roles);
    },
    filterOrganization(filterOrganization) {
      this.getRoles();
    },
    filterOrganizationNull(filterOrganization) {
      this.getRoles();
    },
    filterReseller(filterReseller) {
      this.getRoles();
    },
    filterResellerNull(filterReseller) {
      this.getRoles();
    },
  },
};
</script>
